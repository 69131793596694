import React, { useEffect, useRef } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import { YMInitializer } from "react-yandex-metrika";

import { initMagneticButtons } from "helpers/magneticButtons";
import { yandexMetrikaHit } from "helpers/yandexMetrika";
import MainPage from "./pages/MainPage/MainPage";
import VitaPage from "./pages/ProjectPage/VitaPage";
import MegacvetPage from "./pages/ProjectPage/MegacvetPage";
import GremmPage from "./pages/ProjectPage/GremmPage";
import VitacartaPage from "./pages/ProjectPage/VitacartaPage";
import "./App.css";
import "aos/dist/aos.css";

function App() {
    const containerRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        initMagneticButtons();
        yandexMetrikaHit(location.pathname);
    }, [location]);

    return (
        <LocomotiveScrollProvider
            options={{
                smooth: true,
                smoothMobile: 0,
                smartphone: {
                    smooth: !0,
                },
                tablet: {
                    smooth: !0,
                },
                // mobile: {
                //     smooth: true,
                // },
                // tablet: {
                //     smooth: true,
                // },
            }}
            watch={[location.pathname]}
            onLocationChange={(scroll) => {
                scroll.scrollTo(0, { duration: 0, disableLerp: true });
                scroll.start();
            }}
            containerRef={containerRef}
            location={location.pathname}
        >
            <main data-scroll-container="" ref={containerRef}>
                <YMInitializer
                    accounts={[94236552]}
                    options={{
                        clickmap: true,
                        trackLinks: true,
                        accurateTrackBounce: true,
                        webvisor: true,
                    }}
                    version="2"
                />

                <Routes location={location}>
                    <Route path="/" element={<MainPage />} />
                    <Route path="/vitakit" element={<VitaPage />} />
                    <Route path="/megacvet" element={<MegacvetPage />} />
                    <Route path="/gremm" element={<GremmPage />} />
                    <Route path="/vitacarta" element={<VitacartaPage />} />
                </Routes>
            </main>
        </LocomotiveScrollProvider>
    );
}

export default App;
