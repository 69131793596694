import React from "react";

import handshakeWebp from "./img/handshake.webp";
import handshakePng from "./img/handshake.png";
import "./Problems.scss";

export const Problems = () => {
    return (
        <section className="problems" data-scroll-section="">
            <div className="container">
                <div className="problems__inner">
                    <div className="problems__handshake">
                        <picture>
                            <source srcSet={handshakeWebp} type="image/webp" />
                            <img src={handshakePng} alt="" />
                        </picture>
                        <p>Понимаем наших клиентов</p>
                    </div>
                    <h3
                        className="toFadeDown"
                        data-scroll=""
                        data-scroll-offset="20%"
                        data-scroll-class="fadeUp-active"
                    >
                        Создание продукта - сложный путь. Мы прошли его больше 50 раз и знаем как достичь результата.
                        Узнайте, как мы реализуем проекты от начала до конца в нашем портфолио:
                    </h3>
                </div>
            </div>

            <div className="problems__marquee problems__marquee--left">
                <div className="problems__marquee-content">
                    <div className="problems__marquee-item">Как не попасть в “Производственный ад”</div>
                    <div className="problems__marquee-item">Важно успеть к дедлайну</div>
                    <div className="problems__marquee-item">Как не попасть в “Производственный ад”</div>
                    <div className="problems__marquee-item">Важно успеть к дедлайну</div>
                </div>
                <div className="problems__marquee-content">
                    <div className="problems__marquee-item">Как не попасть в “Производственный ад”</div>
                    <div className="problems__marquee-item">Важно успеть к дедлайну</div>
                    <div className="problems__marquee-item">Как не попасть в “Производственный ад”</div>
                    <div className="problems__marquee-item">Важно успеть к дедлайну</div>
                </div>
            </div>

            <div className="problems__marquee problems__marquee--right">
                <div className="problems__marquee-content">
                    <div className="problems__marquee-item">Как донести информацию до клиентов</div>
                    <div className="problems__marquee-item">Как понять аудиторию</div>
                    <div className="problems__marquee-item">Как отразить наше видение</div>
                    <div className="problems__marquee-item">Как донести информацию до клиентов</div>
                    <div className="problems__marquee-item">Как понять аудиторию</div>
                    <div className="problems__marquee-item">Как отразить наше видение</div>
                </div>
                <div className="problems__marquee-content">
                    <div className="problems__marquee-item">Как донести информацию до клиентов</div>
                    <div className="problems__marquee-item">Как понять аудиторию</div>
                    <div className="problems__marquee-item">Как отразить наше видение</div>
                    <div className="problems__marquee-item">Как донести информацию до клиентов</div>
                    <div className="problems__marquee-item">Как понять аудиторию</div>
                    <div className="problems__marquee-item">Как отразить наше видение</div>
                </div>
            </div>
        </section>
    );
};
