export const splideOptions = {
    type: "loop",
    mediaQuery: "min",
    autoWidth: false,
    // wheel: true,
    // waitForTransition: true,
    // releaseWheel: true,
    // wheelMinThreshold: 0,
    // wheelSleep: 0,
    speed: 2000,
    arrows: true,
    pagination: false,
    breakpoints: {
        320: {
            perPage: 2,
            gap: 10,
        },
        640: {
            perPage: 3,
        },
        992: {
            perPage: 2,
            gap: 20,
        },
        1240: {
            perPage: 3,
        },
    },
};
