import React from "react";
import { Link } from "react-router-dom";

import logo from "img/icons/logo.svg";
import "./Header.scss";

export const Header = ({ onLinkHandler, showMobileMenu }) => {
    return (
        <header className="header-main block-lighter" data-scroll-section="" id="top-scroll">
            <div className="container">
                <div className="header-main__inner">
                    <Link to="/">
                        <div className="header-main__logo">
                            <img src={logo} alt="Polydev" />
                        </div>
                    </Link>
                    <nav className="header-main__nav">
                        <a href="#" className="animated-link" onClick={(e) => onLinkHandler(e, "#about")}>
                            <span>О нас</span>
                            <div className="line"></div>
                        </a>
                        <a href="#" className="animated-link" onClick={(e) => onLinkHandler(e, "#projects")}>
                            <span>Проекты</span>
                            <div className="line"></div>
                        </a>
                        <a href="#" className="animated-link" onClick={(e) => onLinkHandler(e, "#contacts")}>
                            <span>Контакты</span>
                            <div className="line"></div>
                        </a>
                    </nav>
                    <button className="burger" onClick={showMobileMenu} />
                </div>
            </div>
        </header>
    );
};
