import React from "react";
import "./ProjectAbout.css";
import anime from "animejs";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import { Link } from "react-router-dom";

export default function ProjectAbout(props) {
    const { scroll } = useLocomotiveScroll();
    function showItems() {
        const items = document.getElementsByClassName("aboutProjectItem");
        const animation = anime.timeline({
            easing: "linear",
            duration: 100,
        });
        for (let i = 0; i < items.length; i++) {
            animation.add({
                targets: items[i],
                translateY: 0,
                delay: 1,
                opacity: 1,
            });
        }
    }
    if (scroll) {
        scroll.on("call", (args) => {
            if (args === "innerFade") {
                showItems();
            }
        });
    }
    return (
        <div className="projectAbout">
            <div
                data-scroll-offset="20%"
                data-scroll=""
                data-scroll-class="fadeUp-active"
                className="container toFadeUp"
            >
                <h2>О проекте</h2>
                <div className="row">
                    <div className="textProjectAbout">
                        <div className="client">
                            <label>Клиент</label>
                            <p>{props.text}</p>
                        </div>
                        <div className="year">
                            <label className="yearLabel">Год</label>
                            <span>{props.year}</span>
                        </div>
                    </div>
                    <div
                        data-scroll-offset="20%"
                        data-scroll=""
                        data-scroll-call="innerFade"
                        className="solvedProblems"
                    >
                        <label>Решённые задачи</label>
                        <div className="inner">
                            {props.tasks.map((task, taskOrderId) => (
                                <div key={taskOrderId} className="row toFadeUp aboutProjectItem">
                                    <span>{`0${taskOrderId + 1}`}</span>
                                    <p>{task}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="year yearMob">
                        <label className="yearLabel">Год</label>
                        <span>{props.year}</span>
                    </div>
                </div>
            </div>

            <div
                data-scroll-offset="20%"
                data-scroll=""
                data-scroll-class="fadeUp-active"
                className="container toFadeUp"
            >
                <div className="contact-us">
                    <h3 className="contact-us__title">Как создать свое приложение?</h3>
                    <p className="contact-us__subtitle">
                        Мы расскажем как создать приложение мечты и сколько это будет стоить на бесплатной консультации
                    </p>
                    <div className={`secondLine ${props.appName}`}>
                        <div className="absHolder">
                            <Link to="/#contacts">
                                <button
                                    type="button"
                                    data-strength-text="50"
                                    data-strength="100"
                                    className="animatedCircleButton buttonScroller magnetic"
                                >
                                    <p>Связаться с нами</p>
                                    <div className="buttonBack btn-fill" />
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
