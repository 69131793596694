import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useLocomotiveScroll } from "react-locomotive-scroll";

import { Header } from "./components/Header/Header";
import { Hero } from "./components/Hero/Hero";
import { Projects } from "./components/Projects/Projects";
import { About } from "./components/About/About";
import { Problems } from "./components/Problems/Problems";
import { Steps } from "./components/Steps/Steps";
import { AppsBlock } from "./components/AppsBlock/AppsBlock";
import { FAQ } from "./components/FAQ/FAQ";
import { Contacts } from "./components/Contacts/Contacts";
import { Footer } from "./components/Footer/Footer";
import { MobileMenu } from "./components/MobileMenu/MobileMenu";
import "styles/main.scss";

export default function MainPage() {
    const { scroll } = useLocomotiveScroll();
    const { hash } = useLocation();
    const [isShowMobileMenu, setIsShowMobileMenu] = useState(false);
    const [modalText, setModalText] = useState(null);

    useEffect(() => {
        if (hash && scroll) {
            const target = document.querySelector(`${hash}-scroll`);
            let timeout = null;

            if (target) {
                timeout = setTimeout(() => {
                    scroll.scrollTo(document.querySelector(`${hash}-scroll`));
                }, 50);
            }

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [hash, scroll]);

    const onLinkHandler = (e, hash) => {
        e.preventDefault();

        if (isShowMobileMenu) {
            setIsShowMobileMenu(false);
        }

        if (hash && scroll) {
            const target = document.querySelector(`${hash}-scroll`);

            if (target) {
                scroll.scrollTo(document.querySelector(`${hash}-scroll`));
            }
        }
    };

    const onToggleMobileMenuHandler = () => {
        setIsShowMobileMenu(!isShowMobileMenu);
    };

    const showModalHandler = (text) => {
        setModalText(text);

        setTimeout(() => {
            setModalText(null);
        }, 2500);
    };

    return (
        <>
            <Header onLinkHandler={onLinkHandler} showMobileMenu={onToggleMobileMenuHandler} />
            <Hero showModalHandler={showModalHandler} />
            <About />
            <Problems />
            <Projects />
            <Steps />
            <AppsBlock />
            <FAQ onLinkHandler={onLinkHandler} />
            <Contacts showModalHandler={showModalHandler} />
            <Footer onLinkHandler={onLinkHandler} />

            <MobileMenu
                isShow={isShowMobileMenu}
                closeMobileMenu={onToggleMobileMenuHandler}
                onLinkHandler={onLinkHandler}
            />

            <div className={`modal ${modalText ? "show" : ""}`} id="modal">
                <div className="container">
                    <p className="modal__content">{modalText}</p>
                </div>
            </div>
        </>
    );
}
