import gsap from "gsap";
import { Elastic, Power4, Power2, Power3 } from "gsap/gsap-core";
import $ from "jquery";

// Переделать этот ужас...
export const initMagneticButtons = () => {
    // Magnetic Buttons
    // Found via: https://codepen.io/tdesero/pen/RmoxQg
    const magnets = document.querySelectorAll(".magnetic");

    function moveMagnet(event) {
        const magnetButton = event.currentTarget;
        const bounding = magnetButton.getBoundingClientRect();
        const magnetsStrength = magnetButton.getAttribute("data-strength");
        const magnetsStrengthText = magnetButton.getAttribute("data-strength-text");

        gsap.to(magnetButton, 1.5, {
            x: ((event.clientX - bounding.left) / magnetButton.offsetWidth - 0.5) * magnetsStrength,
            y: ((event.clientY - bounding.top) / magnetButton.offsetHeight - 0.5) * magnetsStrength,
            rotate: "0.001deg",
            ease: Power4.easeOut,
        });
        gsap.to($(this).find(".btn-text"), 1.5, {
            // eslint-disable-next-line max-len
            x: ((event.clientX - bounding.left) / magnetButton.offsetWidth - 0.5) * magnetsStrengthText,
            // eslint-disable-next-line max-len
            y: ((event.clientY - bounding.top) / magnetButton.offsetHeight - 0.5) * magnetsStrengthText,
            rotate: "0.001deg",
            ease: Power4.easeOut,
        });
    }
    if (window.innerWidth > 540) {
        magnets.forEach((magnet) => {
            magnet.addEventListener("mousemove", moveMagnet);
            // eslint-disable-next-line func-names
            magnet.addEventListener("mouseleave", function (event) {
                gsap.to(event.currentTarget, 1.5, {
                    x: 0,
                    y: 0,
                    ease: Elastic.easeOut,
                });
                gsap.to($(this).find(".btn-text"), 1.5, {
                    x: 0,
                    y: 0,
                    ease: Elastic.easeOut,
                });
            });
        });
    }
    // eslint-disable-next-line func-names
    $(".magnetic")
        .on("mouseenter", function () {
            if ($(this).find(".btn-fill").length) {
                gsap.to($(this).find(".btn-fill"), 0.6, {
                    startAt: { y: "76%" },
                    y: "0%",
                    ease: Power2.easeInOut,
                });
            }
            if ($(this).find(".buttonBackLong").length) {
                gsap.to($(this).find(".buttonBackLong"), 0.6, {
                    startAt: { x: "-100%" },
                    x: "0%",
                    ease: Power2.easeInOut,
                });
            }
            if ($(this).find(".btn-text-inner.change").length) {
                gsap.to($(this).find(".btn-text-inner.change"), 0.3, {
                    startAt: { color: "#1C1D20" },
                    color: "#FFFFFF",
                    ease: Power3.easeIn,
                });
            }
            // eslint-disable-next-line react/no-this-in-sfc
            $(this.parentNode).removeClass("not-active");
        })

        // eslint-disable-next-line func-names
        .on("mouseleave", function () {
            if ($(this).find(".btn-fill").length) {
                gsap.to($(this).find(".btn-fill"), 0.6, {
                    y: "-76%",
                    ease: Power2.easeInOut,
                });
            }
            if ($(this).find(".buttonBackLong").length) {
                gsap.to($(this).find(".buttonBackLong"), 0.6, {
                    x: "100%",
                    ease: Power2.easeInOut,
                });
            }
            if ($(this).find(".btn-text-inner.change").length) {
                gsap.to($(this).find(".btn-text-inner.change"), 0.3, {
                    color: "#1C1D20",
                    ease: Power3.easeOut,
                    delay: 0.3,
                });
            }
            // eslint-disable-next-line react/no-this-in-sfc
            $(this.parentNode).removeClass("not-active");
        });
};
