import React from "react";
import { Link } from "react-router-dom";

import "./AnimatedLink.css";

export default function AnimatedLink({ text, link }) {
    return (
        <Link to={link} className="animatedLink">
            <span>{text}</span>
            <div className="bottomLine" />
        </Link>
    );
}
