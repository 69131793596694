export const mouseMoveHandler = (e, element) => {
    const pos = element.getBoundingClientRect();
    const mx = e.clientX - pos.left - pos.width / 2;
    const my = e.clientY - pos.top - pos.height / 2;

    element.style.transform = "translate(" + mx * 0.15 + "px, " + my * 0.3 + "px)";
    element.style.transform += "rotate3d(" + mx * -0.1 + ", " + my * -0.3 + ", 0, 12deg)";
    element.children[0].style.transform = "translate(" + mx * 0.025 + "px, " + my * 0.075 + "px)";
};

export const mouseOutHandler = (element) => {
    element.style.transform = "translate3d(0px, 0px, 0px)";
    element.style.transform += "rotate3d(0, 0, 0, 0deg)";
    element.children[0].style.transform = "translate3d(0px, 0px, 0px)";
};
