import React from "react";

import "./MobileMenu.scss";

export const MobileMenu = ({ isShow, closeMobileMenu, onLinkHandler }) => {
    return (
        <div className={`mobile-menu ${isShow ? "show" : ""}`} id="mobile-menu">
            <div className="container">
                <div className="mobile-menu__header">
                    <button className="burger active" id="burger-menu-close" onClick={closeMobileMenu} />
                </div>
                <nav className="mobile-nav">
                    <a href="#" onClick={(e) => onLinkHandler(e, "#top")}>
                        Главная
                    </a>
                    <a href="#" onClick={(e) => onLinkHandler(e, "#about")}>
                        О нас
                    </a>
                    <a href="#" onClick={(e) => onLinkHandler(e, "#projects")}>
                        Проекты
                    </a>
                    <a href="#" onClick={(e) => onLinkHandler(e, "#contacts")}>
                        Контакты
                    </a>
                </nav>
                <div className="mobile-socials">
                    <a href="https://dribbble.com/Polydev" target="_blank">
                        Dribbble
                    </a>
                    <a href="https://www.behance.net/polidev_io" target="_blank">
                        Behance
                    </a>
                </div>
                <a href="#" className="mobile-write-us" onClick={(e) => onLinkHandler(e, "#contacts")}>
                    Написать нам
                </a>
            </div>
        </div>
    );
};
