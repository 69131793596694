import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "./img/logo.svg";
import "./Header.css";
import cross from "./img/cross.svg";
import bars from "./img/bars.svg";

import AnimatedLink from "../AnimatedLink/AnimatedLink";
import FilledFromLeft from "../FilledFromLeft/FilledFromLeft";

export default function Header() {
    const location = useLocation();
    const [isMobileMenuOpened, setIsMobileMenuOpened] = React.useState(false);
    function switchMobileMenu() {
        setIsMobileMenuOpened(!isMobileMenuOpened);
    }
    return (
        <div className={location.pathname !== "/contacts" ? "header" : "header white"}>
            <div className="container">
                <Link to="/">
                    <img alt="" src={logo} />
                </Link>

                <div className="headerButtons">
                    <AnimatedLink text="О нас" link="/#about" />
                    <AnimatedLink text="Проекты" link="/#projects" />
                    <AnimatedLink text="Контакты" link="/#contacts" />
                </div>
                <button onClick={switchMobileMenu} type="button" className="mobileButtonForMenu">
                    <img alt="" src={bars} />
                </button>
            </div>
            <div className={isMobileMenuOpened ? "mobileMenu opened" : "mobileMenu"}>
                <div className="container">
                    <button className="closeButton" type="button" onClick={() => switchMobileMenu()}>
                        <img alt="" src={cross} />
                    </button>
                    <div className="links">
                        <Link to="/">
                            <p onClick={switchMobileMenu}>Главная</p>
                        </Link>
                        <Link to="/#about">
                            <p onClick={switchMobileMenu}>О нас</p>
                        </Link>
                        <Link to="/#projects">
                            <p onClick={switchMobileMenu}>Проекты</p>
                        </Link>
                        <Link to="/#contacts">
                            <p onClick={switchMobileMenu}>Контакты</p>
                        </Link>
                    </div>
                    <div className="social">
                        <a
                            href="https://dribbble.com/Polydev"
                            className="animatedLink"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <p onClick={switchMobileMenu}>Dribbble</p>
                        </a>
                        <a
                            href="https://www.behance.net/polidev_io"
                            className="animatedLink"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <p onClick={switchMobileMenu}>Behance</p>
                        </a>
                    </div>
                    <Link to="/#contacts" onClick={switchMobileMenu}>
                        <FilledFromLeft name="Написать нам" />
                    </Link>
                </div>
            </div>
        </div>
    );
}
