import React, { useEffect } from "react";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ProjectBanner from "./atoms/ProjectBanner/ProjectBanner";
import ProjectAbout from "./atoms/ProjectAbout/ProjectAbout";
import MainScreens from "./atoms/MainScreens/MainScreens";
import Footer from "../../shared_components/Footer/Footer";
import LetsWorkTogether from "../../shared_components/LetsWorkTogether/LetsWorkTogether";
import Header from "../../shared_components/Header/Header";
import "./ProjectPage.css";

// 1600px
import bannerImgX1600 from "./img/vitacarta/1600/projectBannerImg.jpg";
import projectImgX1600 from "./img/vitacarta/1600/projectPage.jpg";
import screenImg1X1600 from "./img/vitacarta/1600/screenImg1.jpg";
import screenImg2X1600 from "./img/vitacarta/1600/screenImg2.jpg";
import screenImg3X1600 from "./img/vitacarta/1600/screenImg3.jpg";
import screenImg4X1600 from "./img/vitacarta/1600/screenImg4.jpg";
import screenRecipes1X1600 from "./img/vitacarta/1600/screenRecipes1.jpg";
import screenCheckIn1X1600 from "./img/vitacarta/1600/screenCheckIn1.jpg";
import screenBranding1X1600 from "./img/vitacarta/1600/screenBranding1.jpg";
import screenBranding2X1600 from "./img/vitacarta/1600/screenBranding2.jpg";
import screenBranding3X1600 from "./img/vitacarta/1600/screenBranding3.jpg";
import screenBranding4X1600 from "./img/vitacarta/1600/screenBranding4.jpg";

// 1000px
import bannerImgX1000 from "./img/vitacarta/1000/projectBannerImg.jpg";
import projectImgX1000 from "./img/vitacarta/1000/projectPage.jpg";
import screenImg1X1000 from "./img/vitacarta/1000/screenImg1.jpg";
import screenImg2X1000 from "./img/vitacarta/1000/screenImg2.jpg";
import screenImg3X1000 from "./img/vitacarta/1000/screenImg3.jpg";
import screenImg4X1000 from "./img/vitacarta/1000/screenImg4.jpg";
import screenRecipes1X1000 from "./img/vitacarta/1000/screenRecipes1.jpg";
import screenCheckIn1X1000 from "./img/vitacarta/1000/screenCheckIn1.jpg";
import screenBranding1X1000 from "./img/vitacarta/1000/screenBranding1.jpg";
import screenBranding2X1000 from "./img/vitacarta/1000/screenBranding2.jpg";
import screenBranding3X1000 from "./img/vitacarta/1000/screenBranding3.jpg";
import screenBranding4X1000 from "./img/vitacarta/1000/screenBranding4.jpg";

// 640px
import bannerImgX640 from "./img/vitacarta/640/projectBannerImg.jpg";
import projectImgX640 from "./img/vitacarta/640/projectPage.jpg";
import screenImg1X640 from "./img/vitacarta/640/screenImg1.jpg";
import screenImg2X640 from "./img/vitacarta/640/screenImg2.jpg";
import screenImg3X640 from "./img/vitacarta/640/screenImg3.jpg";
import screenImg4X640 from "./img/vitacarta/640/screenImg4.jpg";
import screenRecipes1X640 from "./img/vitacarta/640/screenRecipes1.jpg";
import screenCheckIn1X640 from "./img/vitacarta/640/screenCheckIn1.jpg";
import screenBranding1X640 from "./img/vitacarta/640/screenBranding1.jpg";
import screenBranding2X640 from "./img/vitacarta/640/screenBranding2.jpg";
import screenBranding3X640 from "./img/vitacarta/640/screenBranding3.jpg";
import screenBranding4X640 from "./img/vitacarta/640/screenBranding4.jpg";

// 320px
import bannerImgX320 from "./img/vitacarta/320/projectBannerImg.jpg";
import projectImgX320 from "./img/vitacarta/320/projectPage.jpg";
import screenImg1X320 from "./img/vitacarta/320/screenImg1.jpg";
import screenImg2X320 from "./img/vitacarta/320/screenImg2.jpg";
import screenImg3X320 from "./img/vitacarta/320/screenImg3.jpg";
import screenImg4X320 from "./img/vitacarta/320/screenImg4.jpg";
import screenRecipes1X320 from "./img/vitacarta/320/screenRecipes1.jpg";
import screenCheckIn1X320 from "./img/vitacarta/320/screenCheckIn1.jpg";
import screenBranding1X320 from "./img/vitacarta/320/screenBranding1.jpg";
import screenBranding2X320 from "./img/vitacarta/320/screenBranding2.jpg";
import screenBranding3X320 from "./img/vitacarta/320/screenBranding3.jpg";
import screenBranding4X320 from "./img/vitacarta/320/screenBranding4.jpg";

let bannerImg;
let projectImg;
let screenImg1;
let screenImg2;
let screenImg3;
let screenImg4;
let screenRecipes;
let screenCheckIn;
let screenBranding1;
let screenBranding2;
let screenBranding3;
let screenBranding4;

function updateImgs() {
    if (window.innerWidth >= 1200) {
        bannerImg = bannerImgX1600;
        projectImg = projectImgX1600;
        screenImg1 = screenImg1X1600;
        screenImg2 = screenImg2X1600;
        screenImg3 = screenImg3X1600;
        screenImg4 = screenImg4X1600;
        screenRecipes = screenRecipes1X1600;
        screenCheckIn = screenCheckIn1X1600;
        screenBranding1 = screenBranding1X1600;
        screenBranding2 = screenBranding2X1600;
        screenBranding3 = screenBranding3X1600;
        screenBranding4 = screenBranding4X1600;
    } else if (window.innerWidth < 1200 && window.innerWidth >= 900) {
        bannerImg = bannerImgX1000;
        projectImg = projectImgX1000;
        screenImg1 = screenImg1X1000;
        screenImg2 = screenImg2X1000;
        screenImg3 = screenImg3X1000;
        screenImg4 = screenImg4X1000;
        screenRecipes = screenRecipes1X1000;
        screenCheckIn = screenCheckIn1X1000;
        screenBranding1 = screenBranding1X1000;
        screenBranding2 = screenBranding2X1000;
        screenBranding3 = screenBranding3X1000;
        screenBranding4 = screenBranding4X1000;
    } else if (window.innerWidth < 900 && window.innerWidth >= 600) {
        bannerImg = bannerImgX640;
        projectImg = projectImgX640;
        screenImg1 = screenImg1X640;
        screenImg2 = screenImg2X640;
        screenImg3 = screenImg3X640;
        screenImg4 = screenImg4X640;
        screenRecipes = screenRecipes1X640;
        screenCheckIn = screenCheckIn1X640;
        screenBranding1 = screenBranding1X640;
        screenBranding2 = screenBranding2X640;
        screenBranding3 = screenBranding3X640;
        screenBranding4 = screenBranding4X640;
    } else if (window.innerWidth < 600) {
        bannerImg = bannerImgX320;
        projectImg = projectImgX320;
        screenImg1 = screenImg1X320;
        screenImg2 = screenImg2X320;
        screenImg3 = screenImg3X320;
        screenImg4 = screenImg4X320;
        screenRecipes = screenRecipes1X320;
        screenCheckIn = screenCheckIn1X320;
        screenBranding1 = screenBranding1X320;
        screenBranding2 = screenBranding2X320;
        screenBranding3 = screenBranding3X320;
        screenBranding4 = screenBranding4X320;
    }
}
updateImgs();

const initPageData = {
    banner: {
        name: "Витакарта ",
        services: "Разработка",
        image: bannerImg,
        buttonText: "Смотреть приложение",
        buttonLinkIOS:
            "https://apps.apple.com/ru/app/%D0%B2%D0%B8%D1%82%D0%B0%D0%BA%D0%B0%D1%80%D1%82%D0%B0/id996982058",
        buttonLinkAndroid: "https://play.google.com/store/apps/details?id=ru.platinn.vitacore&hl=en&gl=RU",
    },
    about: {
        text: "Витакарта — это приложение, которое позволяет самостоятельно записать на приём к врачу, как взрослого, так и ребенка, посмотреть историю посещений. «Витакарта» позволяет экономить время и не посещать поликлиники для записи на приём. Также есть возможность узнать информацию о медицинских организациях региона.",
        tasks: ["Разработали приложение на основе дизайна, предоставленного заказчиком."],
        year: "2021 — 2022",
    },
    firstImage: projectImg,
    mainScreens: {
        isScreens: false,
        title: "Основные экраны",
        images: [
            {
                img: screenImg1,
                type: "largeImg",
            },
            {
                img: screenImg2,
                type: "mediumImg",
            },
            {
                img: screenImg3,
                type: "mediumImg",
            },
            {
                img: screenImg4,
                type: "longLowImgH",
            },
        ],
    },
    secondScreens: {
        isScreens: false,
        title: "Рецепты",
        images: [
            {
                img: screenRecipes,
                type: "largeImg",
            },
        ],
    },
    thirdScreens: {
        isScreens: false,
        title: "Запись на приём",
        images: [
            {
                img: screenCheckIn,
                type: "longLowImg",
            },
        ],
    },
    branding: {
        isScreens: false,
        title: "Брэндинг",
        blocks: [
            {
                img: screenBranding1,
                type: "longLowImg",
            },
            {
                img: screenBranding2,
                type: "mediumImg",
            },
            {
                img: [screenBranding3, screenBranding4],
                type: "leftBottomRightTopImgs",
            },
        ],
    },
};

export default function VitacartaPage() {
    const [pageData, setPageData] = React.useState(initPageData);
    const [currentSize, setCurrentSize] = React.useState(0);
    const { scroll } = useLocomotiveScroll();
    const { width } = useWindowDimensions();

    function updateCaseObject() {
        const newPageData = { ...pageData };

        newPageData.banner.image = bannerImg;
        newPageData.firstImage = projectImg;
        newPageData.mainScreens.images = [
            {
                img: screenImg1,
                type: "largeImg",
            },
            {
                img: screenImg2,
                type: "mediumImg",
            },
            {
                img: screenImg3,
                type: "mediumImg",
            },
            {
                img: screenImg4,
                type: "longLowImgH",
            },
        ];
        newPageData.secondScreens.images = [
            {
                img: screenRecipes,
                type: "largeImg",
            },
        ];
        newPageData.thirdScreens.images = [
            {
                img: screenCheckIn,
                type: "longLowImg",
            },
        ];
        newPageData.branding.blocks = [
            {
                img: screenBranding1,
                type: "longLowImg",
            },
            {
                img: screenBranding2,
                type: "mediumImg",
            },
            {
                img: [screenBranding3, screenBranding4],
                type: "leftBottomRightTopImgs",
            },
        ];

        setPageData(newPageData);
    }

    useEffect(() => {
        if (width >= 1200) {
            if (currentSize !== 1440) {
                updateImgs();
                updateCaseObject();
                setCurrentSize(1440);
            }
        } else if (width < 1200 && width >= 900) {
            if (currentSize !== 1000) {
                updateImgs();
                updateCaseObject();
                setCurrentSize(1000);
            }
        } else if (width < 900 && width >= 600) {
            if (currentSize !== 640) {
                updateImgs();
                updateCaseObject();
                setCurrentSize(640);
            }
        } else if (width < 600) {
            if (currentSize !== 320) {
                updateImgs();
                updateCaseObject();
                setCurrentSize(320);
            }
        }
    }, [width]);

    useEffect(() => {
        if (scroll) {
            const header = document.getElementsByClassName("header")[0];
            let prevScrollpos = 0;
            scroll.on("scroll", (e) => {
                const currentScrollPos = e.scroll.y;
                if (currentScrollPos > 0) {
                    header.classList.add("filled");
                }
                if (currentScrollPos - prevScrollpos > 10) {
                    header.classList.add("hidden");
                    prevScrollpos = currentScrollPos;
                }
                if (prevScrollpos - currentScrollPos > 10) {
                    header.classList.remove("hidden");
                    prevScrollpos = currentScrollPos;
                }
                if (currentScrollPos < 50) {
                    header.classList.remove("filled");
                }
                if (currentScrollPos < 10) {
                    header.classList.remove("hidden");
                }
            });
        }
    }, [scroll]);

    return (
        <div data-scroll-section="" className="projectPage">
            <Header color="black" />
            <ProjectBanner
                name={pageData.banner.name}
                services={pageData.banner.services}
                image={pageData.banner.image}
                buttonText={pageData.banner.buttonText}
                linkIOS={pageData.banner.buttonLinkIOS}
                linkAndroid={pageData.banner.buttonLinkAndroid}
            />
            <ProjectAbout
                text={pageData.about.text}
                tasks={pageData.about.tasks}
                year={pageData.about.year}
                appName="vitacarta"
            />

            <div className="parallaxBlock">
                <img data-scroll="" data-scroll-speed="-1.5" alt="" src={pageData.firstImage} />
            </div>

            <MainScreens
                isScreens={pageData.mainScreens.isScreens}
                title={pageData.mainScreens.title}
                blocks={pageData.mainScreens.images}
            />
            <MainScreens
                isScreens={pageData.secondScreens.isScreens}
                title={pageData.secondScreens.title}
                blocks={pageData.secondScreens.images}
            />
            <MainScreens
                isScreens={pageData.thirdScreens.isScreens}
                title={pageData.thirdScreens.title}
                blocks={pageData.thirdScreens.images}
            />
            <MainScreens
                isScreens={pageData.branding.isScreens}
                title={pageData.branding.title}
                blocks={pageData.branding.blocks}
            />
            <LetsWorkTogether />
            <Footer />
        </div>
    );
}
