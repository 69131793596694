import React from "react";

import logo from "img/icons/logo.svg";
import arrowDown from "img/icons/arrow-down.svg";
import presentation from "assets/presentation.pdf";
import "./Footer.scss";

export const Footer = ({ onLinkHandler }) => {
    return (
        <footer className="footer-main" data-scroll-section="">
            <div className="container" data-scroll="" data-scroll-speed="-4">
                <div
                    className="footer-main__inner toFadeDown"
                    data-scroll=""
                    data-scroll-offset="20%"
                    data-scroll-class="fadeUp-active"
                >
                    <div className="footer-main__top">
                        <a href="/">
                            <div className="footer-main__logo">
                                <img src={logo} alt="" />
                            </div>
                        </a>

                        <div className="footer-main__middle">
                            <nav className="footer-main__nav">
                                <a href="#" className="animated-link" onClick={(e) => onLinkHandler(e, "#top")}>
                                    <span>Главная</span>
                                    <div className="line"></div>
                                </a>
                                <a href="#" className="animated-link" onClick={(e) => onLinkHandler(e, "#about")}>
                                    <span>О нас</span>
                                    <div className="line"></div>
                                </a>
                                <a href="#" className="animated-link" onClick={(e) => onLinkHandler(e, "#projects")}>
                                    <span>Проекты</span>
                                    <div className="line"></div>
                                </a>
                                <a href="#" className="animated-link" onClick={(e) => onLinkHandler(e, "#contacts")}>
                                    <span>Контакты</span>
                                    <div className="line"></div>
                                </a>
                            </nav>
                            <div className="footer-main__links">
                                <a href="https://dribbble.com/Polydev" target="_blank" className="animated-link">
                                    <span>Dribbble</span>
                                    <div className="line"></div>
                                </a>
                                <a href="https://www.behance.net/polidev_io" target="_blank" className="animated-link">
                                    <span>Behance</span>
                                    <div className="line"></div>
                                </a>
                            </div>
                        </div>

                        <div>
                            <a href={presentation} className="footer-main__presentation" download>
                                <div className="footer-main__presentation-icon">
                                    <img src={arrowDown} alt="" />
                                </div>
                                <div>
                                    <p>Скачать презентацию</p>
                                    <p>PDF, 8.7 mb</p>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="footer-main__line"></div>

                    <div className="footer-main__bottom">
                        <span className="footer-main__copyright">©Polydev, 2023</span>

                        <a href="https://dribbble.com/Polydev" target="_blank" className="animated-link">
                            <span>Dribbble</span>
                            <div className="line"></div>
                        </a>
                        <a href="https://www.behance.net/polidev_io" target="_blank" className="animated-link">
                            <span>Behance</span>
                            <div className="line"></div>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};
