import React, { useEffect } from "react";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ProjectBanner from "./atoms/ProjectBanner/ProjectBanner";
import ProjectAbout from "./atoms/ProjectAbout/ProjectAbout";
import MainScreens from "./atoms/MainScreens/MainScreens";
import Footer from "../../shared_components/Footer/Footer";
import LetsWorkTogether from "../../shared_components/LetsWorkTogether/LetsWorkTogether";
import Header from "../../shared_components/Header/Header";
import "./ProjectPage.css";

// 1600px
import bannerImgX1600 from "./img/megacvet/1600/projectBannerImg.jpg";
import projectImgX1600 from "./img/megacvet/1600/projectPage.jpg";
import screenImg1X1600 from "./img/megacvet/1600/screenImg1.jpg";
import screenImg2X1600 from "./img/megacvet/1600/screenImg2.jpg";
import screenImg3X1600 from "./img/megacvet/1600/screenImg3.jpg";
import screenImg4X1600 from "./img/megacvet/1600/screenImg4.jpg";
import screenAccount1X1600 from "./img/megacvet/1600/screenAccount1.jpg";
import screenAccount2X1600 from "./img/megacvet/1600/screenAccount2.jpg";
import screenAccount3X1600 from "./img/megacvet/1600/screenAccount3.jpg";
import screenAccount4X1600 from "./img/megacvet/1600/screenAccount4.jpg";
import screenBranding1X1600 from "./img/megacvet/1600/screenBranding1.jpg";
import screenBranding2X1600 from "./img/megacvet/1600/screenBranding2.jpg";
import screenBranding3X1600 from "./img/megacvet/1600/screenBranding3.jpg";
import screenBranding4X1600 from "./img/megacvet/1600/screenBranding4.jpg";

// 1000px
import bannerImgX1000 from "./img/megacvet/1000/projectBannerImg.jpg";
import projectImgX1000 from "./img/megacvet/1000/projectPage.jpg";
import screenImg1X1000 from "./img/megacvet/1000/screenImg1.jpg";
import screenImg2X1000 from "./img/megacvet/1000/screenImg2.jpg";
import screenImg3X1000 from "./img/megacvet/1000/screenImg3.jpg";
import screenImg4X1000 from "./img/megacvet/1000/screenImg4.jpg";
import screenAccount1X1000 from "./img/megacvet/1000/screenAccount1.jpg";
import screenAccount2X1000 from "./img/megacvet/1000/screenAccount2.jpg";
import screenAccount3X1000 from "./img/megacvet/1000/screenAccount3.jpg";
import screenAccount4X1000 from "./img/megacvet/1000/screenAccount4.jpg";
import screenBranding1X1000 from "./img/megacvet/1000/screenBranding1.jpg";
import screenBranding2X1000 from "./img/megacvet/1000/screenBranding2.jpg";
import screenBranding3X1000 from "./img/megacvet/1000/screenBranding3.jpg";
import screenBranding4X1000 from "./img/megacvet/1000/screenBranding4.jpg";

// 640px
import bannerImgX640 from "./img/megacvet/640/projectBannerImg.jpg";
import projectImgX640 from "./img/megacvet/640/projectPage.jpg";
import screenImg1X640 from "./img/megacvet/640/screenImg1.jpg";
import screenImg2X640 from "./img/megacvet/640/screenImg2.jpg";
import screenImg3X640 from "./img/megacvet/640/screenImg3.jpg";
import screenImg4X640 from "./img/megacvet/640/screenImg4.jpg";
import screenAccount1X640 from "./img/megacvet/640/screenAccount1.jpg";
import screenAccount2X640 from "./img/megacvet/640/screenAccount2.jpg";
import screenAccount3X640 from "./img/megacvet/640/screenAccount3.jpg";
import screenAccount4X640 from "./img/megacvet/640/screenAccount4.jpg";
import screenBranding1X640 from "./img/megacvet/640/screenBranding1.jpg";
import screenBranding2X640 from "./img/megacvet/640/screenBranding2.jpg";
import screenBranding3X640 from "./img/megacvet/640/screenBranding3.jpg";
import screenBranding4X640 from "./img/megacvet/640/screenBranding4.jpg";

// 320px
import bannerImgX320 from "./img/megacvet/320/projectBannerImg.jpg";
import projectImgX320 from "./img/megacvet/320/projectPage.jpg";
import screenImg1X320 from "./img/megacvet/320/screenImg1.jpg";
import screenImg2X320 from "./img/megacvet/320/screenImg2.jpg";
import screenImg3X320 from "./img/megacvet/320/screenImg3.jpg";
import screenImg4X320 from "./img/megacvet/320/screenImg4.jpg";
import screenAccount1X320 from "./img/megacvet/320/screenAccount1.jpg";
import screenAccount2X320 from "./img/megacvet/320/screenAccount2.jpg";
import screenAccount3X320 from "./img/megacvet/320/screenAccount3.jpg";
import screenAccount4X320 from "./img/megacvet/320/screenAccount4.jpg";
import screenBranding1X320 from "./img/megacvet/320/screenBranding1.jpg";
import screenBranding2X320 from "./img/megacvet/320/screenBranding2.jpg";
import screenBranding3X320 from "./img/megacvet/320/screenBranding3.jpg";
import screenBranding4X320 from "./img/megacvet/320/screenBranding4.jpg";

let bannerImg;
let projectImg;
let screenImg1;
let screenImg2;
let screenImg3;
let screenImg4;
let screenAccount1;
let screenAccount2;
let screenAccount3;
let screenAccount4;
let screenBranding1;
let screenBranding2;
let screenBranding3;
let screenBranding4;

function updateImgs() {
    if (window.innerWidth >= 1200) {
        bannerImg = bannerImgX1600;
        projectImg = projectImgX1600;
        screenImg1 = screenImg1X1600;
        screenImg2 = screenImg2X1600;
        screenImg3 = screenImg3X1600;
        screenImg4 = screenImg4X1600;
        screenAccount1 = screenAccount1X1600;
        screenAccount2 = screenAccount2X1600;
        screenAccount3 = screenAccount3X1600;
        screenAccount4 = screenAccount4X1600;
        screenBranding1 = screenBranding1X1600;
        screenBranding2 = screenBranding2X1600;
        screenBranding3 = screenBranding3X1600;
        screenBranding4 = screenBranding4X1600;
    } else if (window.innerWidth < 1200 && window.innerWidth >= 900) {
        bannerImg = bannerImgX1000;
        projectImg = projectImgX1000;
        screenImg1 = screenImg1X1000;
        screenImg2 = screenImg2X1000;
        screenImg3 = screenImg3X1000;
        screenImg4 = screenImg4X1000;
        screenAccount1 = screenAccount1X1000;
        screenAccount2 = screenAccount2X1000;
        screenAccount3 = screenAccount3X1000;
        screenAccount4 = screenAccount4X1000;
        screenBranding1 = screenBranding1X1000;
        screenBranding2 = screenBranding2X1000;
        screenBranding3 = screenBranding3X1000;
        screenBranding4 = screenBranding4X1000;
    } else if (window.innerWidth < 900 && window.innerWidth >= 600) {
        bannerImg = bannerImgX640;
        projectImg = projectImgX640;
        screenImg1 = screenImg1X640;
        screenImg2 = screenImg2X640;
        screenImg3 = screenImg3X640;
        screenImg4 = screenImg4X640;
        screenAccount1 = screenAccount1X640;
        screenAccount2 = screenAccount2X640;
        screenAccount3 = screenAccount3X640;
        screenAccount4 = screenAccount4X640;
        screenBranding1 = screenBranding1X640;
        screenBranding2 = screenBranding2X640;
        screenBranding3 = screenBranding3X640;
        screenBranding4 = screenBranding4X640;
    } else if (window.innerWidth < 600) {
        bannerImg = bannerImgX320;
        projectImg = projectImgX320;
        screenImg1 = screenImg1X320;
        screenImg2 = screenImg2X320;
        screenImg3 = screenImg3X320;
        screenImg4 = screenImg4X320;
        screenAccount1 = screenAccount1X320;
        screenAccount2 = screenAccount2X320;
        screenAccount3 = screenAccount3X320;
        screenAccount4 = screenAccount4X320;
        screenBranding1 = screenBranding1X320;
        screenBranding2 = screenBranding2X320;
        screenBranding3 = screenBranding3X320;
        screenBranding4 = screenBranding4X320;
    }
}
updateImgs();

const initPageData = {
    banner: {
        name: "Мегацвет24 ",
        services: "Дизайн приложений iOS и Android, разработка",
        image: bannerImg,
        buttonText: "Смотреть приложение",
        buttonLinkIOS: "https://apps.apple.com/ru/app/%D0%BC%D0%B5%D0%B3%D0%B0%D1%86%D0%B2%D0%B5%D1%8224/id1644169059",
        buttonLinkAndroid: "https://play.google.com/store/apps/details?id=io.polydev.megacvet24",
    },
    about: {
        text: "Мегацвет 24 — крупный московский магазин цветов. С целью улучшения сервиса для лояльных пользователей компания решила создать мобильное приложение по заказу доставки цветов онлайн. Клиент попросил сохранить визуальный стиль сайта, освежив его, и использовать функционал сайта с учётом ограничений.",
        tasks: [
            "Анализ рынка",
            "Проектирование структуры",
            "Дизайн приложения iOS",
            "Дизайн приложения Android",
            "Разработка приложения iOS",
            "Разработка приложения Android",
        ],
        year: "2022, лето",
    },
    firstImage: projectImg,
    mainScreens: {
        isScreens: true,
        label: "Основные экраны",
        text: "Так как Мегацвет24 — это приложение доставки, основными разделами приложения являются каталог и корзина. Каталог состоит из основных категорий товаров, популярных и новых товаров. Также есть функции поиска, сортировки и фильтрации.",
        images: [
            {
                img: screenImg1,
                type: "largeImg",
            },
            {
                img: screenImg2,
                type: "mediumImg",
            },
            {
                img: screenImg3,
                type: "mediumImg",
            },
            {
                img: screenImg4,
                type: "largeImg",
            },
        ],
    },
    textWithScreens: {
        isScreens: false,
        title: "Личный кабинет",
        blocks: [
            {
                label: "Описание",
                p: "В личном кабинете можно отслеживать совершённые заказы. Также есть функция индивидуальной скидки: пользователь может получать скидки на заказы, совершённые по личным праздникам, например дням рождения или годовщинам.",
                type: "textBlock",
            },
            {
                img: screenAccount1,
                type: "mediumImg",
            },
            {
                img: screenAccount2,
                type: "longLowImgH",
            },
            {
                img: screenAccount3,
                type: "mediumImg",
            },
            {
                label: "Описание",
                p: "Пользователь может быстро войти в профиль через номер телефона и SMS-код. Конечно, необязтельно авторизовываться, чтобы совершить покупку.",
                type: "textBlockRight",
            },
            {
                img: screenAccount4,
                type: "longLowImgH",
            },
        ],
    },
    branding: {
        isScreens: false,
        title: "Брэндинг",
        blocks: [
            {
                img: screenBranding1,
                type: "longLowImg",
            },
            {
                img: screenBranding2,
                type: "mediumImg",
            },
            {
                img: [screenBranding3, screenBranding4],
                type: "leftBottomRightTopImgs",
            },
        ],
    },
};

export default function MegacvetPage() {
    const [pageData, setPageData] = React.useState(initPageData);
    const [currentSize, setCurrentSize] = React.useState(0);
    const { scroll } = useLocomotiveScroll();
    const { width } = useWindowDimensions();

    function updateCaseObject() {
        const newPageData = { ...pageData };

        newPageData.banner.image = bannerImg;
        newPageData.firstImage = projectImg;
        newPageData.mainScreens.images = [
            {
                img: screenImg1,
                type: "largeImg",
            },
            {
                img: screenImg2,
                type: "mediumImg",
            },
            {
                img: screenImg3,
                type: "mediumImg",
            },
            {
                img: screenImg4,
                type: "largeImg",
            },
        ];
        newPageData.textWithScreens.blocks = [
            {
                label: "Описание функционала",
                p: "В личном кабинете можно отслеживать совершённые заказы. Также есть функция индивидуальной скидки: пользователь может получать скидки на заказы, совершённые по личным праздникам, например дням рождения или годовщинам.",
                type: "textBlock",
            },
            {
                img: screenAccount1,
                type: "mediumImg",
            },
            {
                img: screenAccount2,
                type: "longLowImgH",
            },
            {
                img: screenAccount3,
                type: "mediumImg",
            },
            {
                label: "Описание",
                p: "Пользователь может быстро войти в профиль через номер телефона и SMS-код. Конечно, необязтельно авторизовываться, чтобы совершить покупку.",
                type: "textBlockRight",
            },
            {
                img: screenAccount4,
                type: "longLowImgH",
            },
        ];
        newPageData.branding.blocks = [
            {
                img: screenBranding1,
                type: "longLowImg",
            },
            {
                img: screenBranding2,
                type: "mediumImg",
            },
            {
                img: [screenBranding3, screenBranding4],
                type: "leftBottomRightTopImgs",
            },
        ];

        setPageData(newPageData);
    }

    useEffect(() => {
        if (width >= 1200) {
            if (currentSize !== 1440) {
                updateImgs();
                updateCaseObject();
                setCurrentSize(1440);
            }
        } else if (width < 1200 && width >= 900) {
            if (currentSize !== 1000) {
                updateImgs();
                updateCaseObject();
                setCurrentSize(1000);
            }
        } else if (width < 900 && width >= 600) {
            if (currentSize !== 640) {
                updateImgs();
                updateCaseObject();
                setCurrentSize(640);
            }
        } else if (width < 600) {
            if (currentSize !== 320) {
                updateImgs();
                updateCaseObject();
                setCurrentSize(320);
            }
        }
    }, [width]);

    useEffect(() => {
        if (scroll) {
            const header = document.getElementsByClassName("header")[0];
            let prevScrollpos = 0;
            scroll.on("scroll", (e) => {
                const currentScrollPos = e.scroll.y;
                if (currentScrollPos > 0) {
                    header.classList.add("filled");
                }
                if (currentScrollPos - prevScrollpos > 10) {
                    header.classList.add("hidden");
                    prevScrollpos = currentScrollPos;
                }
                if (prevScrollpos - currentScrollPos > 10) {
                    header.classList.remove("hidden");
                    prevScrollpos = currentScrollPos;
                }
                if (currentScrollPos < 50) {
                    header.classList.remove("filled");
                }
                if (currentScrollPos < 10) {
                    header.classList.remove("hidden");
                }
            });
        }
    }, [scroll]);

    return (
        <div data-scroll-section="" className="projectPage">
            <Header color="black" />
            <ProjectBanner
                name={pageData.banner.name}
                services={pageData.banner.services}
                image={pageData.banner.image}
                buttonText={pageData.banner.buttonText}
                linkIOS={pageData.banner.buttonLinkIOS}
                linkAndroid={pageData.banner.buttonLinkAndroid}
            />
            <ProjectAbout
                text={pageData.about.text}
                tasks={pageData.about.tasks}
                year={pageData.about.year}
                appName="megacvet"
            />

            <div className="parallaxBlock">
                <img data-scroll="" data-scroll-speed="-1.5" alt="" src={pageData.firstImage} />
            </div>

            <MainScreens
                isScreens={pageData.mainScreens.isScreens}
                label={pageData.mainScreens.label}
                text={pageData.mainScreens.text}
                blocks={pageData.mainScreens.images}
            />
            <MainScreens
                isScreens={pageData.textWithScreens.isScreens}
                title={pageData.textWithScreens.title}
                blocks={pageData.textWithScreens.blocks}
            />
            <MainScreens
                isScreens={pageData.branding.isScreens}
                title={pageData.branding.title}
                blocks={pageData.branding.blocks}
            />

            <LetsWorkTogether />
            <Footer />
        </div>
    );
}
