import React from "react";

import "./FAQ.scss";

export const FAQ = ({ onLinkHandler }) => {
    const onAccordionToggleHandler = (e) => {
        const item = e.target.closest(".faq__item");

        if (item) {
            item.classList.toggle("opened");
        }
    };

    return (
        <section className="faq" data-scroll-section="">
            <div className="container">
                <div className="faq__inner">
                    <h2
                        className="block-title toFadeDown"
                        data-scroll=""
                        data-scroll-offset="20%"
                        data-scroll-class="fadeUp-active"
                    >
                        Вопросы и ответы
                    </h2>

                    <ul
                        className="faq__list toFadeDown"
                        id="faq-list"
                        data-scroll=""
                        data-scroll-offset="20%"
                        data-scroll-class="fadeUp-active"
                    >
                        <li
                            className="faq__item toFadeDown"
                            data-scroll=""
                            data-scroll-offset="20%"
                            data-scroll-class="fadeUp-active"
                            onClick={onAccordionToggleHandler}
                        >
                            <span>01</span>
                            <div className="content">
                                <h5>Сколько будет стоить разработка?</h5>
                                <p>
                                    Стоимость среднего приложения 500 000 - 750 000₽. Мы скажем точную цену когда
                                    обсудим ваш проект.
                                </p>
                            </div>
                            <div className="cross">
                                <div className="cross-vertical"></div>
                                <div className="cross-horizontal"></div>
                            </div>
                        </li>
                        <li
                            className="faq__item toFadeDown"
                            data-scroll=""
                            data-scroll-offset="20%"
                            data-scroll-class="fadeUp-active"
                            onClick={onAccordionToggleHandler}
                        >
                            <span>02</span>
                            <div className="content">
                                <h5>Поддерживаете ли вы приложение после релиза?</h5>
                                <p>
                                    Да, мы считаем что релиз проекта - это только начало. После него мы помогаем
                                    улучшать и модернизировать продукт, следить за его работой и обновлять до последних
                                    версий ОС.
                                </p>
                            </div>
                            <div className="cross">
                                <div className="cross-vertical"></div>
                                <div className="cross-horizontal"></div>
                            </div>
                        </li>
                        <li
                            className="faq__item toFadeDown"
                            data-scroll=""
                            data-scroll-offset="20%"
                            data-scroll-class="fadeUp-active"
                            onClick={onAccordionToggleHandler}
                        >
                            <span>03</span>
                            <div className="content">
                                <h5>Из чего именно состоит создание приложения?</h5>
                                <p>
                                    Каждую работу мы начинаем с погружения в бизнес и анализа рынка. Мы проведем
                                    несколько встреч чтобы понять ваш продукт и точно понять задачи которые стоят перед
                                    приложением. После проведем анализ ЦА и конкурентных продуктов, найдем лучшие
                                    практики. На основе проделанной работы мы сделаем дизайн и после воплотим его в
                                    приложение.
                                </p>
                            </div>
                            <div className="cross">
                                <div className="cross-vertical"></div>
                                <div className="cross-horizontal"></div>
                            </div>
                        </li>
                        <li
                            className="faq__item toFadeDown"
                            data-scroll=""
                            data-scroll-offset="20%"
                            data-scroll-class="fadeUp-active"
                            onClick={onAccordionToggleHandler}
                        >
                            <span>04</span>
                            <div className="content">
                                <h5>Вы занимаетесь только созданием мобильных приложений?</h5>
                                <p>
                                    Нет, также мы создаем сайты. Зачастую это сложные веб-приложения и корпоративные
                                    сайты.
                                </p>
                            </div>
                            <div className="cross">
                                <div className="cross-vertical"></div>
                                <div className="cross-horizontal"></div>
                            </div>
                        </li>
                        <li
                            className="faq__item toFadeDown"
                            data-scroll=""
                            data-scroll-offset="20%"
                            data-scroll-class="fadeUp-active"
                            onClick={onAccordionToggleHandler}
                        >
                            <span>05</span>
                            <div className="content">
                                <h5>Остались вопросы?</h5>
                                <p>
                                    <a href="#" className="green-link" onClick={(e) => onLinkHandler(e, "#contacts")}>
                                        Оставьте заявку
                                    </a>
                                    или свяжитесь с нами по номеру <a href="tel:+79959870598">+7 995 987 05 98</a> и мы
                                    ответим на любой ваш вопрос!
                                </p>
                            </div>
                            <div className="cross">
                                <div className="cross-vertical"></div>
                                <div className="cross-horizontal"></div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
};
