import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIMask } from "react-imask";

import sendForm from "services/sendForm";
import { mouseMoveHandler, mouseOutHandler } from "helpers/mouseHandlers";
import { yandexMetrikaHit } from "helpers/yandexMetrika";
import headerTitleWebp from "img/header-title.webp";
import headerTitlePng from "img/header-title.png";
import "./Hero.scss";

export const Hero = ({ showModalHandler }) => {
    const navigate = useNavigate();
    const buttonRef = useRef(null);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [maskOptions, setMaskOptions] = useState({
        mask: "+{7} (#00) 000 00 00",
        lazy: true,
        definitions: {
            "#": /^[0-7,9]{1}$/,
        },
    });
    const { ref } = useIMask(maskOptions, {
        onAccept: (value) => setPhone(value),
    });

    useEffect(() => {
        if (buttonRef?.current) {
            const magnetic = buttonRef.current;

            magnetic.addEventListener("mousemove", (e) => mouseMoveHandler(e, magnetic));
            magnetic.addEventListener("mouseout", () => mouseOutHandler(magnetic));
        }
    }, [buttonRef]);

    useEffect(() => {
        if (ref?.current) {
            ref.current.addEventListener("focus", () => setMaskOptions({ ...maskOptions, lazy: false }));
            ref.current.addEventListener("blur", () => setMaskOptions({ ...maskOptions, lazy: true }));
        }
    }, [ref]);

    const submitHandler = async (e) => {
        e.preventDefault();

        const regex = /^\+7 \([0-9]{3}\) [0-9]{3} [0-9]{2} [0-9]{2}$/im;

        if (name.trim() && regex.test(phone)) {
            const formData = new FormData();
            formData.append("name", name.trim());
            formData.append("phone", phone);

            const result = await sendForm(formData);

            if (result) {
                setName("");
                setPhone("");
                showModalHandler("Заявка успешно отправлена!");
                yandexMetrikaHit("/?thanks");
                navigate("/?thanks");
            } else {
                showModalHandler("Ошибка при отправке заявки");
            }
        }
    };

    const changeNameHandler = (e) => {
        const { value } = e.target;

        if (value.length <= 30) {
            setName(e.target.value);
        }
    };

    return (
        <section className="hero block-lighter" data-scroll-section="">
            <div className="container">
                <div className="hero__inner">
                    <div className="hero__header">
                        <h1 className="hero__title">
                            <span className="first">Разрабатываем мобильные</span>{" "}
                            <span className="second">приложения</span>{" "}
                            <picture className="desktop fourth">
                                <source srcSet={headerTitleWebp} type="image/webp" />
                                <img src={headerTitlePng} alt="" />
                            </picture>
                            <span className="third desktop">для бизнеса</span>{" "}
                            <span className="fourth">от 500 000 рублей</span>
                        </h1>

                        <div className="hero__header-img tablet fifth">
                            <picture>
                                <source srcSet={headerTitleWebp} type="image/webp" />
                                <img src={headerTitlePng} alt="" />
                            </picture>
                        </div>

                        <p className="hero__subtitle six">Есть идея? Оставьте заявку и мы поможем с ее реализацией</p>
                    </div>

                    <form className="hero__form contact__form six" id="contact-form" onSubmit={submitHandler}>
                        <div className="hero__form-name contact__form-field animated-input">
                            <input
                                value={name}
                                onChange={changeNameHandler}
                                type="text"
                                name="name"
                                id="header-input-name"
                                placeholder=" "
                                autoComplete="off"
                                maxLength="30"
                                required
                            />
                            <label htmlFor="header-input-name">Имя*</label>
                        </div>
                        <div className="hero__form-phone contact__form-field animated-input">
                            <input
                                ref={ref}
                                type="text"
                                name="phone"
                                id="header-input-phone"
                                placeholder=" "
                                autoComplete="off"
                                className="phone-mask"
                                required
                            />
                            <label htmlFor="header-input-phone">Телефон*</label>
                        </div>
                        <button
                            data-strength-text="50"
                            data-strength="100"
                            className="hero__form-button green-button animation-magnetic"
                            type="submit"
                            ref={buttonRef}
                        >
                            <span>Оставить заявку</span>
                        </button>
                    </form>
                    <p className="contact__form-desc six">
                        Нажимая кнопку “Оставить заявку”, вы даёте согласие на обработку персональных данных
                    </p>
                </div>
            </div>
        </section>
    );
};
