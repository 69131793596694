export default async function sendForm(formData) {
    try {
        const response = await fetch("/api/sendForm.php", {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            return null;
        }

        return response.json();
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        return null;
    }
}
