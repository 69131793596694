import React from "react";

import appsImgWebp from "./img/apps-img.webp";
import appsImgPng from "./img/apps-img.png";
import "./AppsBlock.scss";

export const AppsBlock = () => {
    return (
        <section className="apps-img" data-scroll-section="">
            <picture data-scroll="" data-scroll-speed="2">
                <source srcSet={appsImgWebp} type="image/webp" />
                <img src={appsImgPng} alt="" />
            </picture>
        </section>
    );
};
