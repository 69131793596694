import React from "react";

import aboutStar from "img/icons/about-star.svg";
import "./About.scss";

export const About = () => {
    return (
        <section className="about" id="about-scroll" data-scroll-section="">
            <div className="about__background">
                <div className="container">
                    <div className="about__inner">
                        <h2
                            className="about__title toFadeDown"
                            data-scroll=""
                            data-scroll-offset="20%"
                            data-scroll-class="fadeUp-active"
                        >
                            Преимущества <img src={aboutStar} alt="" />
                        </h2>
                        <div
                            className="about__blocks toFadeDown"
                            data-scroll=""
                            data-scroll-offset="20%"
                            data-scroll-class="fadeUp-active"
                        >
                            <p className="about__blocks-item">
                                77% покупок в интернете совершают через мобильные приложения
                            </p>
                            <p className="about__blocks-item">Среднее время окупаемости наших приложений 9 месяцев</p>
                            <p className="about__blocks-item">
                                Будем поддерживать и развивать приложение даже после релиза
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
