import React from "react";
import { Link } from "react-router-dom";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

import { splideOptions } from "./slideOptions";
import presentation from "assets/presentation.pdf";
import "./Projects.scss";

export const Projects = () => {
    return (
        <section className="projects" id="projects-scroll" data-scroll-section="">
            <div className="container">
                <div className="projects__inner">
                    <h2
                        className="projects__title block-title toFadeDown"
                        data-scroll=""
                        data-scroll-offset="20%"
                        data-scroll-class="fadeUp-active"
                    >
                        Наши проекты <span>50+</span>
                    </h2>

                    <Splide hasTrack={false} options={splideOptions}>
                        <div
                            className="projects__list toFadeDown"
                            data-scroll=""
                            data-scroll-offset="20%"
                            data-scroll-class="fadeUp-active"
                        >
                            <div className="splide__arrows">
                                <button className="splide__arrow splide__arrow--prev">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 40 40"
                                        width="40"
                                        height="40"
                                        focusable="false"
                                    >
                                        <path d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z"></path>
                                    </svg>
                                </button>
                                <button className="splide__arrow splide__arrow--next">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 40 40"
                                        width="40"
                                        height="40"
                                        focusable="false"
                                    >
                                        <path d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z"></path>
                                    </svg>
                                </button>
                            </div>
                            <div className="projects__list-inner">
                                <SplideTrack>
                                    <SplideSlide>
                                        <Link to="/vitacarta" className="splide__slide">
                                            <div className="projects__card">
                                                <div className="projects__card-inner vitacard">
                                                    <div>
                                                        <span className="projects__card-tag">Разработка</span>
                                                    </div>
                                                    <p className="projects__card-name">Витакарта</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </SplideSlide>
                                    <SplideSlide>
                                        <Link to="/megacvet" className="splide__slide">
                                            <div className="projects__card">
                                                <div className="projects__card-inner megacvet">
                                                    <div>
                                                        <span className="projects__card-tag">UX/UI</span>
                                                        <span className="projects__card-tag">Разработка</span>
                                                        <span className="projects__card-tag">Поддержка</span>
                                                    </div>
                                                    <p className="projects__card-name">Мегацвет24</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </SplideSlide>

                                    <SplideSlide>
                                        <Link to="/gremm" className="splide__slide">
                                            <div className="projects__card">
                                                <div className="projects__card-inner gremm">
                                                    <div>
                                                        <span className="projects__card-tag">UX/UI</span>
                                                        <span className="projects__card-tag">Разработка</span>
                                                        <span className="projects__card-tag">Поддержка</span>
                                                    </div>
                                                    <p className="projects__card-name">Грэмм групп</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </SplideSlide>
                                    <SplideSlide>
                                        <Link to="/vitakit" className="splide__slide">
                                            <div className="projects__card">
                                                <div className="projects__card-inner vitakit">
                                                    <div>
                                                        <span className="projects__card-tag">Разработка</span>
                                                    </div>
                                                    <p className="projects__card-name">Vitakit</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </SplideSlide>
                                    <SplideSlide>
                                        <a href={presentation} className="splide__slide" download>
                                            <div className="projects__card">
                                                <div className="projects__card-inner all">
                                                    <div>
                                                        <span className="projects__card-tag">UX/UI</span>
                                                        <span className="projects__card-tag">Разработка</span>
                                                        <span className="projects__card-tag">Поддержка</span>
                                                    </div>
                                                    <p className="projects__card-name">Презентация проектов</p>
                                                </div>
                                            </div>
                                        </a>
                                    </SplideSlide>
                                </SplideTrack>
                            </div>
                        </div>
                    </Splide>
                </div>
            </div>
        </section>
    );
};
