import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIMask } from "react-imask";

import sendForm from "services/sendForm";
import { mouseMoveHandler, mouseOutHandler } from "helpers/mouseHandlers";
import { yandexMetrikaHit } from "helpers/yandexMetrika";
import arrowDownLeft from "img/icons/arrow-down-left.svg";
import "./Contacts.scss";

export const Contacts = ({ showModalHandler }) => {
    const navigate = useNavigate();
    const buttonRef = useRef(null);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [maskOptions, setMaskOptions] = useState({
        mask: "+{7} (#00) 000 00 00",
        lazy: true,
        definitions: {
            "#": /^[0-7,9]{1}$/,
        },
    });
    const { ref } = useIMask(maskOptions, {
        onAccept: (value) => setPhone(value),
    });

    useEffect(() => {
        if (buttonRef?.current) {
            const magnetic = buttonRef.current;

            magnetic.addEventListener("mousemove", (e) => mouseMoveHandler(e, magnetic));
            magnetic.addEventListener("mouseout", () => mouseOutHandler(magnetic));
        }
    }, [buttonRef]);

    useEffect(() => {
        if (ref?.current) {
            ref.current.addEventListener("focus", () => setMaskOptions({ ...maskOptions, lazy: false }));
            ref.current.addEventListener("blur", () => setMaskOptions({ ...maskOptions, lazy: true }));
        }
    }, [ref]);

    const submitHandler = async (e) => {
        e.preventDefault();

        const regex = /^\+7 \([0-9]{3}\) [0-9]{3} [0-9]{2} [0-9]{2}$/im;

        if (name.trim() && regex.test(phone)) {
            const formData = new FormData();
            formData.append("name", name.trim());
            formData.append("phone", phone);

            const result = await sendForm(formData);

            if (result) {
                setName("");
                setPhone("");
                showModalHandler("Заявка успешно отправлена!");
                yandexMetrikaHit("/?thanks");
                navigate("/?thanks");
            } else {
                showModalHandler("Ошибка при отправке заявки");
            }
        }
    };

    const changeNameHandler = (e) => {
        const { value } = e.target;

        if (value.length <= 30) {
            setName(e.target.value);
        }
    };

    return (
        <div className="contacts__wrapper" data-scroll-section="">
            <section className="contacts block-lighter" id="contacts-scroll">
                <div className="container">
                    <div className="contacts__inner">
                        <div
                            className="contacts__header toFadeDown"
                            data-scroll=""
                            data-scroll-offset="20%"
                            data-scroll-class="fadeUp-active"
                        >
                            <h3 className="contacts__title">Разрабатываем мобильные приложения для бизнеса</h3>
                            <img src={arrowDownLeft} alt="" />
                        </div>

                        <div className="contacts__content">
                            <form className="contacts__form" id="contact-form-2" onSubmit={submitHandler}>
                                <p
                                    className="contacts__subtitle toFadeDown"
                                    data-scroll=""
                                    data-scroll-offset="20%"
                                    data-scroll-class="fadeUp-active"
                                >
                                    Если вы долистали до этого блока, нам точно есть что обсудить!
                                </p>
                                <div className="contacts__form-fields contact__form">
                                    <div
                                        className="contact__form-field animated-input toFadeDown"
                                        data-scroll=""
                                        data-scroll-offset="20%"
                                        data-scroll-class="fadeUp-active"
                                    >
                                        <input
                                            value={name}
                                            onChange={changeNameHandler}
                                            type="text"
                                            name="name"
                                            id="contacts-input-name"
                                            placeholder=" "
                                            autoComplete="off"
                                            maxLength="30"
                                            required
                                        />
                                        <label htmlFor="contacts-input-name">Имя*</label>
                                    </div>
                                    <div
                                        className="contact__form-field animated-input toFadeDown"
                                        data-scroll=""
                                        data-scroll-offset="20%"
                                        data-scroll-class="fadeUp-active"
                                    >
                                        <input
                                            ref={ref}
                                            type="text"
                                            name="phone"
                                            id="contacts-input-phone"
                                            placeholder=" "
                                            autoComplete="off"
                                            className="phone-mask"
                                            required
                                        />
                                        <label htmlFor="contacts-input-phone">Телефон</label>
                                    </div>
                                    <button
                                        className="green-button animation-magnetic toFadeDown"
                                        type="submit"
                                        data-scroll=""
                                        data-scroll-offset="20%"
                                        data-scroll-class="fadeUp-active"
                                        ref={buttonRef}
                                    >
                                        <span>Оставить заявку</span>
                                    </button>
                                </div>
                                <p
                                    className="contacts__form-desc contact__form-desc toFadeDown"
                                    data-scroll=""
                                    data-scroll-offset="20%"
                                    data-scroll-class="fadeUp-active"
                                >
                                    Нажимая кнопку “Оставить заявку”, вы даёте согласие на обработку персональных данных
                                </p>
                            </form>
                            <div className="contacts__communications">
                                <div
                                    className="contacts__communications-phone toFadeDown"
                                    data-scroll=""
                                    data-scroll-offset="20%"
                                    data-scroll-class="fadeUp-active"
                                >
                                    <a href="tel:+79959870598" className="animated-link">
                                        <span>+7 (995)-987-05-98</span>
                                        <div className="line"></div>
                                    </a>
                                </div>
                                <div
                                    className="contacts__communications-socials toFadeDown"
                                    data-scroll=""
                                    data-scroll-offset="20%"
                                    data-scroll-class="fadeUp-active"
                                >
                                    <a href="https://t.me/polydev_s" className="animated-link">
                                        <span>Telegram</span>
                                        <div className="line"></div>
                                    </a>
                                    <a href="https://wa.me/79959870598" className="animated-link">
                                        <span>WhatsApp</span>
                                        <div className="line"></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
