import React from "react";

import "./Steps.scss";

export const Steps = () => {
    return (
        <section className="steps" data-scroll-section="">
            <div className="container">
                <div className="steps__inner">
                    <h2
                        className="block-title toFadeDown"
                        data-scroll=""
                        data-scroll-offset="20%"
                        data-scroll-class="fadeUp-active"
                    >
                        Этапы проекта
                    </h2>

                    <ul className="steps__list">
                        <li
                            className="steps__item toFadeDown"
                            data-scroll=""
                            data-scroll-offset="20%"
                            data-scroll-class="fadeUp-active"
                        >
                            <h5 className="steps__item-title">Знакомство с проектом</h5>
                            <div className="steps__item-content">
                                <p className="steps__item-desc">Узнаем цели продукта, определяем план работы</p>
                                <p className="steps__item-time">3 дня</p>
                            </div>
                        </li>
                        <li
                            className="steps__item toFadeDown"
                            data-scroll=""
                            data-scroll-offset="20%"
                            data-scroll-class="fadeUp-active"
                        >
                            <h5 className="steps__item-title">Аналитика</h5>
                            <div className="steps__item-content">
                                <p className="steps__item-desc">
                                    Погружаемся в бизнес клиента, изучаем прямых и косвенных конкурентов
                                </p>
                                <p className="steps__item-time">7 дней</p>
                            </div>
                        </li>
                        <li
                            className="steps__item toFadeDown"
                            data-scroll=""
                            data-scroll-offset="20%"
                            data-scroll-class="fadeUp-active"
                        >
                            <h5 className="steps__item-title">Дизайн</h5>
                            <div className="steps__item-content">
                                <p className="steps__item-desc">Создаем визуал будущего продукта</p>
                                <p className="steps__item-time">от 15 дней</p>
                            </div>
                        </li>
                        <li
                            className="steps__item toFadeDown"
                            data-scroll=""
                            data-scroll-offset="20%"
                            data-scroll-class="fadeUp-active"
                        >
                            <h5 className="steps__item-title">Разработка</h5>
                            <div className="steps__item-content">
                                <p className="steps__item-desc">Разрабатываем UI приложения, backend и связываем их</p>
                                <p className="steps__item-time">от 20 дней</p>
                            </div>
                        </li>
                        <li
                            className="steps__item toFadeDown"
                            data-scroll=""
                            data-scroll-offset="20%"
                            data-scroll-class="fadeUp-active"
                        >
                            <h5 className="steps__item-title">Финализация</h5>
                            <div className="steps__item-content">
                                <p className="steps__item-desc">
                                    Презентуем приложение и определяем планы его развития
                                </p>
                                <p className="steps__item-time">5 дней</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
};
